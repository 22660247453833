/* SIMPLEBAR */
.simplebar-content {
    height: 100%;
}

.simplebar-scrollbar:before {
    background-color: #aaa;
    opacity: 0.6;
    transition: background-color 0.1s linear;
    cursor: pointer;
}

.simplebar-scrollbar.simplebar-visible:before {
    opacity: 0.6;
    transition: background-color 0.1s linear;
}

.simplebar-track.simplebar-vertical {
    top: 0;
    width: 13px;
    z-index: 15;
    cursor: pointer;
}

.simplebar-track.simplebar-vertical.simplebar-hover .simplebar-scrollbar::before,
.simplebar-dragging .simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
    opacity: 1;
    background-color: #0F5562;
    transition: background-color 0.1s linear;
    cursor: pointer;
}

.simplebar-track.simplebar-horizontal {
    left: 0;
    bottom: 2px;
    z-index: 15;
    height: 10px;
    cursor: pointer;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    height: 10px;
    top: 0px;
}

.simplebar-track.simplebar-horizontal.simplebar-hover .simplebar-scrollbar::before,
.simplebar-dragging .simplebar-track.simplebar-horizontal .simplebar-scrollbar::before {
    opacity: 1;
    background-color: #0F5562;
    transition: background-color 0.1s linear;
    cursor: pointer;
}